import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Flex } from "@chakra-ui/react";
import Layout2 from "../../components/layout-2";
import SEO from "../../components/seo";

const FoodPage = () => {
  return (
    <Layout2>
      <SEO
        title="Food"
        description="We help you create delicious food products with better ingredients, innovative formulas, and exciting flavours. We also deliver customized formulation for products that consumers will crave."
      />
      <Box
        marginTop={["80px", "80px", "80px", "100px", "100px"]}
        position="relative"
      >
        <Box
          display={["block", "none", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../../images/food-small.jpg"
            // width={800}
            height={900}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="food"
            style={{ display: "block" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "block", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../../images/food-medium.jpg"
            width={800}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="food"
            style={{ display: "block" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "none", "block", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../../images/food.jpg"
            width={1920}
            height={1100}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="food"
            style={{ display: "block" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "block", "none"]}
          position="relative"
        >
          <StaticImage
            src="../../images/food.jpg"
            width={1920}
            height={1100}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="food"
            style={{ display: "block" }}
            // style={{ width: "100%" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "none", "block"]}
          position="relative"
        >
          <StaticImage
            src="../../images/food.jpg"
            width={1920}
            height={800}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="food"
            style={{ display: "block" }}
          />
        </Box>
        {/* <StaticImage
          src="../../images/human-nutrition.jpg"
          placeholder="tracedSVG"
          tracedSVGOptions={{ color: "#282765" }}
          width={1900}
          height={900}
          alt="human-nutrition"
          style={{ display: "block", opacity: "0.7" }}
        /> */}
        <Box
          margin="auto"
          position="absolute"
          top="50%"
          left="20%"
          transform="translate(-20%, -50%)"
          width={["85%", "85%", "70%", "60%", "40%"]}
          paddingLeft={["20px", "20px", "0px", "0px", "0px"]}
        >
          <Box width="100%" margin="0 auto">
            <Flex margin="0 auto" flexDirection="column">
              <Box
                as="h1"
                fontSize={[
                  "70px",
                  "60px",
                  "85px",
                  "100px",
                  "115px",
                  "125px",
                  // "120px",
                ]}
                fontWeight="400"
                color="#fff"
                width="100%"
                fontFamily="Fjalla One !important"
                lineHeight={["75px", "65px", "90px", "105px", "120px", "135px"]}
              >
                FOOD
              </Box>
              <Box
                as="p"
                color="#fff"
                fontSize={["19px", "20px", "20px", "23px", "27px"]}
                marginTop={["20px", "20px", "20px", "30px", "30px"]}
                //   fontWeight="500"
              >
                We apply our experience and technical expertise to help you
                formulate and supply quality ingredients that excite consumers.
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box marginTop="100px" padding="1em">
        <Flex
          maxWidth="1000px"
          width="100%"
          margin="0 auto"
          background="linen"
          borderRadius="30px"
          padding="2em"
          flexWrap="wrap"
          // flexDirection={["column", "column", "row", "row", "row"]}
        >
          <Box
            position="relative"
            marginLeft={["0px", "0px", "0px", "-70px", "-120px"]}
            width={["100%", "100%", "55%", "45%", "45%"]}
            alignSelf="center"
            borderRadius="30px"
            overflow="hidden"
          >
            {/* {heroImageMobile ? (
              <StaticImage
                src="../../images/pexels-gustavo-fring-4148842.jpg"
                placeholder="tracedSVG"
                tracedSVGOptions={{ color: "#282765" }}
                width={500}
                height={300}
                alt="human-nutrition"
                style={{ display: "block" }}
              />
            ) : ( */}
            <StaticImage
              src="../../images/food-2.jpg"
              placeholder="tracedSVG"
              tracedSVGOptions={{ color: "#282765" }}
              width={1000}
              // height={1000}
              alt="food"
              style={{ display: "block" }}
            />
            {/* )} */}
          </Box>
          <Box
            marginTop={["30px", "30px", "0px", "0px", "0px"]}
            padding={["0em", "0em", "0em", "2em", "2em"]}
            paddingLeft={["0em", "1em", "2em", "2em", "3em"]}
            paddingRight={["0em", "0em", "0em", "0.5em", "0.5em"]}
            width={["100%", "100%", "45%", "60%", "65%"]}
          >
            <Box
              as="h2"
              fontWeight="400"
              fontSize={["30px", "40px", "43px", "50px", "58px"]}
              color="#252869"
              fontFamily="Roboto Condensed !important"
            >
              Craving Excellence
            </Box>
            <Box
              as="p"
              fontSize={["16px", "16px", "18px", "20px", "20px"]}
              color="#252869"
              paddingTop="1.5em"
            >
              Our ingredients are designed to provide you with healthy choices
              without compromising on flavour. It’s not just what we eat; it’s
              also about how it tastes, and what the products are made of. We
              help you create delicious food products with better ingredients,
              innovative formulas, and exciting flavours. We also deliver
              customized formulation for products that consumers will crave.
            </Box>
          </Box>
        </Flex>
      </Box>
    </Layout2>
  );
};

export default FoodPage;
